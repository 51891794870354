import {
  GET_OLO_ATTRIBUTES,
  GET_POS_MAPPING_DETAILS,
  GET_STORE_SHUTDOWN_INFO,
  SHUTDOWN_BATCH_ID,
  UPDATE_OLO_SUCCESS_STATE,
  GET_PRODUCT_EXCLUSION,
  EXCLUSION_BATCH_ID,
  GET_STORE_HOLIDAY_INFO,
  HOLIDAY_BATCH_ID,
  GET_OLO_BULK_ID_ATTRIBUTES,
  EXCLUSION_LOOKUP_ADVANCE,
  CATEGORY_BASED_GROUP,
  UPDATE_OUT_OF_STOCK,
  OUTOFSTOCK_BATCH_ID,
  EXCLUSION_LOOKUP_DELETE_ADVANCE,
  OUTOFSTOCK_LOOKUP,
  DELETE_OUTOFSTOCK_BATCH,
  UPDATE_DELETE_STATE,
} from '../constants';

const initialState = {
  selectedStoreOloAttributes: null,
  selectedStorePosMapping: null,
  selectedStoreShuDownInfo: null,
  selectedStoreExcludeProds: null,
  selectedStoreHolidayInfo: null,
  holidayBatchId: null,
  exclusionBatchId: null,
  shutdownBatchID: '',
  successState: null,
  oloBatchID: null,
  exclusionLookupAdvance: null,
  categoryBasedGroup: null,
  updateOutOfStock: null,
  deleteBulkAcnowledge: null,
  outOfStockLookUp: null,
  deleteOutOfStockBatch: null,
  deleteNormalExclusion: null,
};

export default function OloManagementReducer(state = initialState, action) {
  switch (action.type) {
    case GET_OLO_ATTRIBUTES:
      return {
        ...state,
        selectedStoreOloAttributes: action.payLoad,
      };
    case GET_POS_MAPPING_DETAILS:
      return {
        ...state,
        selectedStorePosMapping: action.payLoad,
      };
    case GET_STORE_SHUTDOWN_INFO:
      return {
        ...state,
        selectedStoreShuDownInfo: action.payLoad,
      };
    case UPDATE_OLO_SUCCESS_STATE:
      return {
        ...state,
        successState: action.payLoad,
      };
    case SHUTDOWN_BATCH_ID:
      return {
        ...state,
        shutdownBatchID: action.payLoad,
      };
    case GET_PRODUCT_EXCLUSION:
      return {
        ...state,
        selectedStoreExcludeProds: action.payLoad,
      };
    case EXCLUSION_BATCH_ID:
      return {
        ...state,
        exclusionBatchId: action.payLoad,
      };
    case GET_OLO_BULK_ID_ATTRIBUTES:
      return {
        ...state,
        oloBatchID: action.payLoad,
      };
    case GET_STORE_HOLIDAY_INFO:
      return {
        ...state,
        selectedStoreHolidayInfo: action.payLoad,
      };
    case HOLIDAY_BATCH_ID:
      return {
        ...state,
        holidayBatchId: action.payLoad,
      };
    case EXCLUSION_LOOKUP_ADVANCE:
      return {
        ...state,
        exclusionLookupAdvance: action.payLoad,
      };
    case CATEGORY_BASED_GROUP:
      return {
        ...state,
        categoryBasedGroup: action.payLoad,
      };
    case UPDATE_OUT_OF_STOCK:
      return {
        ...state,
        updateOutOfStock: action.payLoad,
      };
    case OUTOFSTOCK_BATCH_ID:
      return {
        ...state,
        outOfStockBatchId: action.payLoad,
      };
    case EXCLUSION_LOOKUP_DELETE_ADVANCE:
      return {
        ...state,
        deleteBulkAcnowledge: action.payLoad,
      };
    case OUTOFSTOCK_LOOKUP:
      return {
        ...state,
        outOfStockLookUp: action.payLoad,
      };
    case DELETE_OUTOFSTOCK_BATCH:
      return {
        ...state,
        deleteOutOfStockBatch: action.payLoad,
      };
    case UPDATE_DELETE_STATE:
      return {
        ...state,
        deleteNormalExclusion: action.payLoad,
      };

    default:
      return state;
  }
}
