import {
  GET_KIOSK_CONFIGURATION_DATA, UPDATE_KIOSK_SUCCESS_STATE, ADD_KIOSK_CONFIGURATION,
  DELETE_KIOSK_CONFIGURATION,
} from '../constants';

const initialState = {
  kioskConfigurationData: null,
  successState: null,
  addSuccessStatus: null,
  deleteStatus: null,
};

export default function KioskManagementReducer(state = initialState, action) {
  switch (action.type) {
    case GET_KIOSK_CONFIGURATION_DATA:
      return { ...state, kioskConfigurationData: action.payLoad };
    case UPDATE_KIOSK_SUCCESS_STATE:
      return { ...state, successState: action.payLoad };
    case ADD_KIOSK_CONFIGURATION:
      return { ...state, addSuccessStatus: action.payLoad };
    case DELETE_KIOSK_CONFIGURATION:
      return { ...state, deleteStatus: action.payLoad };
    default:
      return state;
  }
}
