import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';
// import tenantconfig from '../config/tenantConfig.json';

const clientId = process.env.REACT_APP_CLIENT_ID;
const authorityURL = process.env.REACT_APP_AUTHORITY_URL;
const userManagementScopes = process.env.REACT_APP_USER_MANAGEMENT_SCOPES;
const userMgmResponseType = process.env.REACT_APP_USER_MANAGEMENT_RESPONSE_TYPE;

const userManagerConfig = {
  authority: authorityURL, // 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_9tV2czLsM',
  scope: userManagementScopes, // 'openid profile yum_iam_api/get.user.details',
  response_type: userMgmResponseType, // 'code',
  client_id: clientId, // '4ov9uh7qila0qbmpmo5bcdr932',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/signin`,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  silentRequestTimeout: 30000,
  revokeAccessTokenOnSignout: true,
  jwksUri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/assets/jwks.json`,
  // metadata: {
  //   authorization_endpoint: 'https://qayum-giam-connect.auth.us-east-1.amazoncognito.com/oauth2/authorize',
  //   end_session_endpoint: 'https://qayum-giam-connect.auth.us-east-1.amazoncognito.com/logout',
  // },
  // accessTokenExpiringNotificationTime: 60,
  // silent_redirect_uri: `
  // ${ window.location.protocol }//${window.location.hostname}${window.location.port
  // ?`:${window.location.port}` : ''}/signin
  // `,
  // post_logout_redirect_uri: `
  // ${ window.location.protocol }//${window.location.hostname}${window.location.port
  // ?`:${window.location.port}` : ''}/signout
  // `,
};
const userManager = createUserManager(userManagerConfig);
export default userManager;
