import {
  MAPPING_WITH_STORE,
  MENU_ITEM, ALL_PRODUCTS,
  ALL_CATEGORIES, AUTH_ROLES,
  AUTH_NAVIGATIONS,
  AUTH_ERROR,
  GET_RESTAURANT_GROUP,
  POST_RESTAURANT_GROUP,
  GET_RESTAURANT_GROUP_LIST,
  DELETE_RESTAURANT_GROUP,
  GET_RESTAURANT_GROUP_BY_STORE,
  GET_ITEM_GROUP_LIST,
  POST_ITEM_GROUP,
  GET_ITEM_GROUP,
  DELETE_ITEM_GROUP,
  TENANT_CONFIG,
} from '../constants';
import i18n from '../../i18n';
import { uniqueObjects, getAllStoreSpecificCities, isStatusCodeSuccess } from '../../utils';
import { getService, logErrorMessage } from './index';
import { getAuthorizedNavs, revokeAuthentication } from '../../module/auth/Utils';
// import userRoleMock from '../../module/auth/userRoleMock';

export const getMapping = (getStoreObject) => (dispatch) => {
  dispatch({
    type: MAPPING_WITH_STORE,
    payLoad: getStoreObject,
  });
};

export const authError = (authState) => (dispatch) => {
  dispatch({
    type: AUTH_ERROR,
    payLoad: authState,
  });
};

export const gettingALLStore = (flag = true) => async (dispatch) => {
  const response = await getService({
    url: '/stores',
    method: 'get',
    loaderFlag: flag,
  });
  let getStoreObjects = {};
  if (isStatusCodeSuccess(response?.status)) {
    getStoreObjects = {
      owner: uniqueObjects(response?.data, 'owner'),
      storeName: uniqueObjects(response?.data),
      priceTier: uniqueObjects(response?.data, 'pricingTier'),
      // priceTier: tenantConfig.basic.allowPricingTier,
      city: getAllStoreSpecificCities(response?.data, 'address', 'city'),
    };
    dispatch(getMapping(getStoreObjects));
  } else {
    getStoreObjects = {
      owner: [],
      storeName: [],
      priceTier: [],
      city: [],
    };
    dispatch(getMapping(getStoreObjects));
    dispatch(logErrorMessage(response?.data || 'service error '));
  }
};

export const updateMenuItems = (data) => async (dispatch) => {
  dispatch({
    type: MENU_ITEM,
    payLoad: data,
  });
};

export const loadMenuItems = () => async (dispatch) => {
  const response = await getService({
    method: 'get',
    url: '/stores/menu-item',
  });

  if (isStatusCodeSuccess(response?.status)) {
    dispatch(updateMenuItems(response?.data));
  } else {
    dispatch(logErrorMessage(response?.data || 'Service Error'));
  }
};

export const updateAllProducts = (data) => async (dispatch) => {
  dispatch({
    type: ALL_PRODUCTS,
    payLoad: data,
  });
};

export const loadAllProducts = () => async (dispatch) => {
  const response = await getService({
    method: 'get',
    url: 'menu/all-products',
  });

  if (isStatusCodeSuccess(response?.status)) {
    dispatch(updateAllProducts(response?.data));
  } else {
    dispatch(logErrorMessage(response?.data || 'Service Error'));
  }
};

export const updateAllCategories = (data) => async (dispatch) => {
  dispatch({
    type: ALL_CATEGORIES,
    payLoad: data,
  });
};

export const loadAllCategories = () => async (dispatch) => {
  const response = await getService({
    method: 'get',
    url: 'menu/all-categories',
  });

  if (isStatusCodeSuccess(response?.status)) {
    dispatch(updateAllCategories(response?.data));
    // dispatch(updateProductItems(response?.data));
  } else {
    dispatch(logErrorMessage(response?.data || 'Service Error'));
  }
};

export const updateAuthNavigations = (data) => async (dispatch) => {
  dispatch({
    type: AUTH_NAVIGATIONS,
    payLoad: data,
  });
};

export const updateAuthRoles = (data) => async (dispatch) => {
  dispatch({
    type: AUTH_ROLES,
    payLoad: data,
  });
};

export const deleteLocalStorage = () => async (dispatch) => {
  dispatch(getMapping({}));
  dispatch(updateAllCategories(null));
  dispatch(updateMenuItems(null));
};

export const loadAuthorizedRoles = () => async (dispatch) => {
  const response = await getService({
    method: 'get',
    // url: `/stores/get-role/os-kfc-in:${user}`,
    url: '/stores/get-role',
  });

  // mock data will be removed
  // response = { ...response, data: userRoleMock };

  if (isStatusCodeSuccess(response?.status)) {
    dispatch(updateAuthNavigations(getAuthorizedNavs(response.data)));
    dispatch(updateAuthRoles(response.data));
    dispatch(gettingALLStore());
    dispatch(authError(false));
  } else {
    dispatch(deleteLocalStorage());
    // dispatch(updateAuthNavigations(getAuthorizedNavs(null)));
    // dispatch(updateAuthRoles(null));
    dispatch(authError(true));
    revokeAuthentication();
  }
};

export const updateTenantConfig = (data) => async (dispatch) => {
  dispatch({
    type: TENANT_CONFIG,
    payLoad: data,
  });
};

export const loadTenantConfig = () => async (dispatch) => {
  const response = await getService({
    method: 'get',
    url: '/config/tenantconfig',
  });

  if (isStatusCodeSuccess(response?.status)) {
    i18n.reloadResources();
    i18n.changeLanguage(response.data?.basic?.allowedLanguages[0]);
    dispatch(updateTenantConfig(response.data));
  }
};

// added for stor groups

export const getRestaurantGroupListSuccess = (data) => (dispatch) => {
  dispatch({
    type: GET_RESTAURANT_GROUP_LIST,
    payLoad: data,
  });
};

export const getRestaurantGroupList = () => async (dispatch) => {
  const response = await getService({
    url: '/stores/getallstoregroups',
    method: 'GET',
  });

  if (isStatusCodeSuccess(response?.status)) {
    dispatch(getRestaurantGroupListSuccess(response.data));
  } else {
    dispatch(getRestaurantGroupListSuccess([]));
    dispatch(logErrorMessage(i18n.t('responseMessages.error.605')));
  }
};
export const deleteRestaurantGroupSuccess = (data) => (dispatch) => {
  dispatch({
    type: DELETE_RESTAURANT_GROUP,
    payLoad: data,
  });
};

export const deleteRestaurantGroup = (groupId) => async (dispatch) => {
  const response = await getService({
    url: `/stores/deletestoregroup/${groupId}`,
    method: 'DELETE',
  });

  if (isStatusCodeSuccess(response?.status)) {
    dispatch(deleteRestaurantGroupSuccess(i18n.t('responseMessages.success.245')));
    dispatch(getRestaurantGroupList());
  } else {
    dispatch(deleteRestaurantGroupSuccess(i18n.t('responseMessages.error.606')));
    dispatch(logErrorMessage(i18n.t('responseMessages.error.606')));
  }
};
export const getRestaurantGroupSuccess = (data) => (dispatch) => {
  dispatch({
    type: GET_RESTAURANT_GROUP,
    payLoad: data,
  });
};

export const getRestaurantGroup = (groupId) => async (dispatch) => {
  const response = await getService({
    url: `/stores/getstoregroup/${groupId}`,
    method: 'GET',
  });

  if (isStatusCodeSuccess(response?.status)) {
    dispatch(getRestaurantGroupSuccess(response.data));
  } else {
    dispatch(getRestaurantGroupSuccess([]));
    dispatch(logErrorMessage(i18n.t('responseMessages.error.605')));
  }
};

export const postRestaurantGroupSuccess = (data) => (dispatch) => {
  dispatch({
    type: POST_RESTAURANT_GROUP,
    payLoad: data,
  });
};
export const postRestaurantGroup = (bodyData) => async (dispatch) => {
  const response = await getService({
    url: '/stores/addstoregroup',
    method: 'POST',
    data: bodyData,
  });
  if (isStatusCodeSuccess(response?.status)) {
    dispatch(postRestaurantGroupSuccess(i18n.t('responseMessages.success.244')));
    dispatch(getRestaurantGroupList());
  } else {
    if (response.data === 'storeGroup403') {
      dispatch(logErrorMessage(i18n.t('responseMessages.error.storeGroup403')));
    } else {
      dispatch(logErrorMessage(i18n.t('responseMessages.error.604')));
    }
    dispatch(postRestaurantGroupSuccess(null));
  }
};

export const getRestaurantGroupFromStoreSuccess = (data) => (dispatch) => {
  dispatch({
    type: GET_RESTAURANT_GROUP_BY_STORE,
    payLoad: data,
  });
};
export const getRestaurantGroupFromStore = (storeID) => async (dispatch) => {
  const response = await getService({
    url: `stores/get-store-group-basic/${storeID}`,
    method: 'GET',
  });
  if (isStatusCodeSuccess(response?.status)) {
    dispatch(getRestaurantGroupFromStoreSuccess(response.data));
  } else {
    dispatch(logErrorMessage(i18n.t('responseMessages.error.604')));
    dispatch(getRestaurantGroupFromStoreSuccess(null));
  }
};

export const getItemGroupListSuccess = (data) => (dispatch) => {
  dispatch({
    type: GET_ITEM_GROUP_LIST,
    payLoad: data,
  });
};

export const getItemGroupList = () => async (dispatch) => {
  const response = await getService({
    url: '/item-groups/groups',
    method: 'GET',
  });

  if (isStatusCodeSuccess(response?.status)) {
    dispatch(getItemGroupListSuccess(response.data));
  } else {
    dispatch(getItemGroupListSuccess([]));
    dispatch(logErrorMessage(i18n.t('responseMessages.error.621')));
  }
};

export const postItemtGroupSuccess = (data) => (dispatch) => {
  dispatch({
    type: POST_ITEM_GROUP,
    payLoad: data,
  });
};
export const postItemGroup = (bodyData) => async (dispatch) => {
  const response = await getService({
    url: '/item-groups/groups',
    method: 'POST',
    data: bodyData,
  });
  if (isStatusCodeSuccess(response?.status)) {
    dispatch(postItemtGroupSuccess(i18n.t('responseMessages.success.250')));
    dispatch(getItemGroupList());
  } else {
    if (response.data === 'storeGroup403') {
      dispatch(logErrorMessage(i18n.t('responseMessages.error.ItemGroup403')));
    } else {
      dispatch(logErrorMessage(i18n.t('responseMessages.error.612')));
    }
    dispatch(postItemtGroupSuccess(null));
  }
};

export const getItemGroupSuccess = (data) => (dispatch) => {
  dispatch({
    type: GET_ITEM_GROUP,
    payLoad: data,
  });
};

export const getItemGroup = (groupId) => async (dispatch) => {
  const response = await getService({
    url: `/item-groups/groups/${groupId}`,
    method: 'GET',
  });

  if (isStatusCodeSuccess(response?.status)) {
    dispatch(getItemGroupSuccess(response.data));
  } else {
    dispatch(getItemGroupSuccess([]));
    dispatch(logErrorMessage(i18n.t('responseMessages.error.613')));
  }
};

export const deleteItemGroupSuccess = (data) => (dispatch) => {
  dispatch({
    type: DELETE_ITEM_GROUP,
    payLoad: data,
  });
};

export const deleteItemGroup = (groupId) => async (dispatch) => {
  const response = await getService({
    url: `/item-groups/groups/${groupId}`,
    method: 'DELETE',
  });

  if (isStatusCodeSuccess(response?.status)) {
    dispatch(deleteItemGroupSuccess(i18n.t('responseMessages.success.251')));
    dispatch(getItemGroupList());
  } else {
    dispatch(deleteItemGroupSuccess(i18n.t('responseMessages.error.614')));
    dispatch(logErrorMessage(i18n.t('responseMessages.error.614')));
  }
};
