import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';
import { createSelector } from 'reselect';
import Router from './Router';

import { privateRoutes } from './Route.config';
import { getAuthorizedRoutes } from '../module/auth/Utils';
import { loadAuthorizedRoles, loadTenantConfig } from '../common/action/localStore';
import Loader from '../common/loader/Loader';
// import { getTenantConfig } from '../utils/storeUtils';

// const tenantConfig = getTenantConfig();
const authRoutesSelect = createSelector(
  (state) => state.LocalStoreReducer.authRoles,
  (authRoles) => getAuthorizedRoutes(privateRoutes, authRoles),
);

const AppRoutes = ({ publicRoutes, history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.oidc.user);
  const authRoutes = useSelector(authRoutesSelect);
  const isAuthError = useSelector((state) => state.LocalStoreReducer.authError);
  const authRoles = useSelector((state) => state.LocalStoreReducer.authRoles);
  const tenantConfig = useSelector((state) => state.LocalStoreReducer.tenantConfig);
  const isLoading = useSelector((state) => state.CommonReducer.isLoading);

  useEffect(() => {
    !isAuthError && !authRoles && currentUser && dispatch(loadAuthorizedRoles());
    currentUser && (!tenantConfig || !Object.keys(tenantConfig).length) && dispatch(loadTenantConfig());
  }, []);

  return (
    <>
      {isLoading && !tenantConfig && <Loader loader={t('common.Loader')} />}
      <Switch>
        {
          publicRoutes.map((route) => (
            <Router {...{
              path: route.path,
              exact: route.exact,
              component: route.component,
              key: route.id,
              routeConfig: route,
              history,
            }}
            />
          ))
        }
        {
          !tenantConfig && (
            <Redirect {...{
              to: {
                pathname: '/signout',
                state: { from: window.location.pathname },
              },
            }}
            />
          )
        }
        {
          !currentUser ? (
            <Redirect {...{
              to: {
                pathname: '/signin',
                state: { from: window.location.pathname },
              },
            }}
            />
          ) : (
            authRoutes.map((route) => (
              <Router {...{
                path: route.path,
                exact: route.exact,
                component: route.component,
                key: route.id,
                routeConfig: route,
                history,
              }}
              />
            ))
          )
        }
      </Switch>
    </>
  );
};

AppRoutes.propTypes = {
  publicRoutes: PropTypes.instanceOf(Array).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default AppRoutes;
