import {
  GET_CUSTOMER_LIST,
  CUSTOMER_ORDER_HISTORY,
  PROMOTION_REDEMPTION_HISTORY,
  PROMOTION_APPLICATION_HISTORY,
  PRIVATE_PROMOTION,
  APPLY_CUSTOMER_PROMO,
} from '../constants';

const initialState = {
  getCustomerList: null,
  customerOrderHistory: null,
  promotionRedemptionHistory: null,
  promotionApplicationHistory: null,
  privatePromotion: null,
  applyCustomerPromo: null,
};

export default function CustomerManagementReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMER_LIST:
      return {
        ...state,
        getCustomerList: action.payLoad,
      };
    case CUSTOMER_ORDER_HISTORY:
      return {
        ...state,
        customerOrderHistory: action.payLoad,
      };
    case PROMOTION_REDEMPTION_HISTORY:
      return {
        ...state,
        promotionRedemptionHistory: action.payLoad,
      };
    case PROMOTION_APPLICATION_HISTORY:
      return {
        ...state,
        promotionApplicationHistory: action.payLoad,
      };
    case PRIVATE_PROMOTION:
      return {
        ...state,
        privatePromotion: action.payLoad,
      };
    case APPLY_CUSTOMER_PROMO:
      return {
        ...state,
        applyCustomerPromo: action.payLoad,
      };
    default:
      return state;
  }
}
