import i18n from 'i18next';
import axios from 'axios';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import languageDetector from './language-detector';
import { getUserInfo } from './module/auth/Utils';

let user;
let lng;
let namespace;
i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    react: {
      wait: true,
    },
    backend: {
      loadPath: async (lngs, namespaces) => {
        const translationPath = `${process.env.REACT_APP_BASE_URL}/config/translation/${lngs[0]}`;
        [lng] = lngs;
        [namespace] = namespaces;
        user = await getUserInfo();
        return (user && translationPath) || `/locales/${lngs}/${namespaces}.json`;
      },
      crossDomain: true,
      withCredentials: true,
      customHeaders: () => ({
        authorization: `${user?.token_type} ${user?.access_token}`,
      }),
      request: async (options, url, payload, callback) => {
        try {
          const response = await axios({
            url,
            method: 'GET',
            headers: options.customHeaders(),
            data: payload,
          });

          callback(null, { status: 200, data: response.data });
        } catch (err) {
          const localTranslation = await axios({
            url: `/locales/${lng}/${namespace}.json`,
            method: 'GET',
          });
          callback(null, { status: 200, data: localTranslation.data });
        }
      },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    load: 'languageOnly',
    nonExplicitSupportedLngs: true,
  });

export default i18n;
