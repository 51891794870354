import {
  GET_REPORT, GET_TIME_REPORT, GET_ORDER_DETAIL_REPORT, GET_POS_DETAIL_REPORT,
  GET_AGGREGATOR_REPORT, GET_GIFTCARD_SALES_REPORT, REPORT_PAGE_COUNT,
  GET_PAYMENT_DETAIL_REPORT, GET_DELIVERYPARTNER_DETAIL_REPORT, GET_OLD_POS_DETAIL_REPORT,
} from '../constants';

const initialState = {
  report: null,
  totalPages: null,
  timeTableReport: null,
  orderdetails: null,
  posDetails: null,
  aggregatotDetails: null,
  giftCardSalesReport: null,
  reportPageCount: null,
  paymentDetails: null,
  deliveryDetails: null,
};

export default function ReportManagmentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REPORT:
      // if (action.payLoad) {
      return {
        ...state,
        ...action.payLoad,
      };
      // }
      // return {
      //   ...state,
      //   report: null,
      //   totalPages: null,
      // };

      // return action.payLoad ? {
      //   ...state,
      //   ...action.payLoad,
      // } : {
      //   ...state,
      //   report: null,
      //   totalPages: null,
      // };
    case GET_TIME_REPORT:
      return {
        ...state,
        timeTableReport: action.payLoad,
      };
    case GET_ORDER_DETAIL_REPORT:
      return {
        ...state,
        orderdetails: action.payLoad,
      };
    case GET_POS_DETAIL_REPORT:
      return {
        ...state,
        posDetails: action.payLoad,
      };
    case GET_OLD_POS_DETAIL_REPORT:
      return {
        ...state,
        posOldDetails: action.payLoad,
      };
    case GET_AGGREGATOR_REPORT:
      return {
        ...state,
        aggregatotDetails: action.payLoad,
      };
    case GET_GIFTCARD_SALES_REPORT:
      return {
        ...state,
        giftCardSalesReport: action.payLoad,
      };
    case REPORT_PAGE_COUNT:
      return {
        ...state,
        reportPageCount: action.payLoad,
      };
    case GET_PAYMENT_DETAIL_REPORT:
      return {
        ...state,
        paymentDetails: action.payLoad,
      };
    case GET_DELIVERYPARTNER_DETAIL_REPORT:
      return {
        ...state,
        deliveryDetails: action.payLoad,
      };

    default:
      return state;
  }
}
