import Axios from 'axios';
import { IS_LOADING } from '../constants';
import { token } from '../../utils';

const interceptor = (store) => {
  let numberOfAjaxCAllPending = 0;
  Axios.interceptors.request.use(
    (conf) => {
      numberOfAjaxCAllPending += 1;

      !(conf.loader)
        && store.dispatch({
          type: IS_LOADING,
          payLoad: true,
        });
      const req = {
        ...conf,
        headers: {
          ...conf.headers,
          'x-correlation-request-id': token(),
        },
      };
      return req;
    },
    (error) => {
      store.dispatch({
        type: IS_LOADING,
        payLoad: false,
      });
      return Promise.reject(error);
    },
  );
  Axios.interceptors.response.use(
    (next) => {
      numberOfAjaxCAllPending -= 1;
      !numberOfAjaxCAllPending && store.dispatch({
        type: IS_LOADING,
        payLoad: false,
      });
      return Promise.resolve(next);
    },
    (error) => {
      numberOfAjaxCAllPending -= 1;
      !numberOfAjaxCAllPending && store.dispatch({
        type: IS_LOADING,
        payLoad: false,
      });
      return Promise.reject(error);
    },
  );
};

export default {
  interceptor,
};
