import React from 'react';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import axios from './common/action/axios';
import { store, persistor } from './store/store';
import userManager from './store/UserManager';
import AppRouteProvider from './routes/AppRouteProvider';
import './assets/scss/main.scss';

axios.interceptor(store);
function App() {
  return (
    <Provider store={store}>
      <OidcProvider userManager={userManager} store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div className="App" data-testid="app">
            <BrowserRouter>
              <AppRouteProvider />
            </BrowserRouter>
          </div>
        </PersistGate>
      </OidcProvider>
    </Provider>
  );
}

export default App;
