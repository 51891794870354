import { FaBars, FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';

const SidebarArrow = (props) => {
  const { arrowClass, handelClick } = props;

  return (
    <>
      <button
        type="button"
        className={`text-center position-absolute ${arrowClass}`}
        onClick={handelClick}
      >
        {arrowClass.split(' ')[1] === 'rotateButton' ? <FaTimes /> : <FaBars />}
      </button>
    </>
  );
};

SidebarArrow.propTypes = {
  arrowClass: PropTypes.string.isRequired,
  handelClick: PropTypes.func.isRequired,
};

export default SidebarArrow;
