import {
  GET_COMPANY_SETTINGS,
  POST_COMPANY_SETTINGS,
} from '../constants';

const initialState = {
  getCompanySettings: null,
  postCompanySettings: null,

};

export default function ConfigManagementReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY_SETTINGS:
      return {
        ...state,
        getCompanySettings: action.payLoad,
      };
    case POST_COMPANY_SETTINGS:
      return {
        ...state,
        postCompanySettings: action.payLoad,
      };
    default:
      return state;
  }
}
