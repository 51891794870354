import PropTypes from 'prop-types';

const ErrorFallback = (props) => {
  const { info } = props;
  return (
    <section className="error-fallback-container d-flex container justify-content-center">
      <section className="align-self-center">
        <article className="fallback-wrapper">
          <h1>Some Error Occurred</h1>
          <p>{info}</p>
        </article>
      </section>
    </section>
  );
};

ErrorFallback.propTypes = {
  info: PropTypes.string.isRequired,
};

export default ErrorFallback;
