import {
  PAYMENTBANK_LIST, PAYMENTBANK_ADDED, PAYMENTBANK_DELETED,
} from '../constants';

const initialState = {
  paymentList: null,
  paymentAdded: null,
  paymentDeleted: null,
};

export default function PaymentConfigurationReducer(state = initialState, action) {
  switch (action.type) {
    case PAYMENTBANK_LIST:
      return {
        ...state,
        paymentList: action.payLoad,
      };
    case PAYMENTBANK_ADDED:
      return {
        ...state,
        paymentAdded: action.payLoad,
      };
    case PAYMENTBANK_DELETED:
      return {
        ...state,
        paymentDeleted: action.payLoad,
      };
    default:
      return { ...state };
  }
}
