export const GET_REPORT = 'GET_REPORT';
export const GET_DETAIL_REPORT = 'GET_DETAIL_REPORT';
export const GET_TIME_REPORT = 'GET_TIME_REPORT';
export const GET_ORDER_DETAIL_REPORT = 'GET_ORDER_DETAIL_REPORT';
export const GET_POS_DETAIL_REPORT = 'GET_POS_DETAIL_REPORT';
export const GET_AGGREGATOR_REPORT = 'GET_AGGREGATOR_REPORT';
export const GET_GIFTCARD_SALES_REPORT = 'GET_GIFTCARD_SALES_REPORT';
export const REPORT_PAGE_COUNT = 'REPORT_PAGE_COUNT';
export const GET_PAYMENT_DETAIL_REPORT = 'GET_PAYMENT_DETAIL_REPORT';
export const GET_DELIVERYPARTNER_DETAIL_REPORT = 'GET_DELIVERYPARTNER_DETAIL_REPORT';
export const GET_OLD_POS_DETAIL_REPORT = 'GET_OLD_POS_DETAIL_REPORT';
