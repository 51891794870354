import {
  GET_ALL_PROMOTIONS,
  GET_ALL_COUPONS,
  GET_ALL_AGGREGATOR_OFFERS,
  GET_CUSTOMER_INFO,
  UPDATE_COUPON_SUCCESS_STATE,
} from '../constants';

const initialState = {
  allPromotions: null,
  allCoupons: null,
  allAggregatorOffers: null,
  successState: null,
  customerInfo: null,
};

export default function CouponManagmentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PROMOTIONS:
      return {
        ...state,
        allPromotions: action.payLoad,
      };
    case GET_ALL_COUPONS:
      return {
        ...state,
        allCoupons: action.payLoad,
      };
    case GET_ALL_AGGREGATOR_OFFERS:
      return {
        ...state,
        allAggregatorOffers: action.payLoad,
      };
    case GET_CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: action.payLoad,
      };
    case UPDATE_COUPON_SUCCESS_STATE:
      return {
        ...state,
        successState: action.payLoad,
      };
    default:
      return state;
  }
}
