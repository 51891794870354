import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { loadUser } from 'redux-oidc';
import { persistStore } from 'redux-persist';
import rootReducer from '../reducers';
import userManager from './UserManager';

const composeEnhancers = process.env.REACT_APP_ENV === 'prod'
|| process.env.REACT_APP_ENV === 'qa' || process.env.REACT_APP_ENV === 'uat'
  ? compose
  : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
loadUser(store, userManager);

export const persistor = persistStore(store);
