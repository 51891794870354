import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';
import { get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Header from '../common/header/Header';
import ErrorBoundry from '../common/errorboundary/ErrorBoundary';
import SidebarComponent from '../common/sidebar/SidebarComponent';

const Router = ({
  component: Component, routeConfig, location, history, ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Helmet>
            <title>{`${t(routeConfig?.pageName)}-${process.env.REACT_APP_NAME}`}</title>
          </Helmet>
          <div
            className={`${(process.env.REACT_APP_ENV !== 'development' && get(routeConfig, 'pageName')) && get(routeConfig, 'pageName').split(' ').join('')} position-relative d-flex`}
          >
            {routeConfig.private && (
            <SidebarComponent location={location} {...props} />
            )}
            {routeConfig.private && (
            <Header location={location} {...props} />
            )}
            <div className="d-flex container-fluid px-0 position-relative">
              <div className="main-content-container pl-md-4">
                <ErrorBoundry>
                  <Component routeConfig={routeConfig} {...props} />
                </ErrorBoundry>
              </div>
            </div>
          </div>
        </>
      )}
    />
  );
};

Router.propTypes = {
  component: PropTypes.instanceOf(Object).isRequired,
  routeConfig: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object).isRequired,
};

Router.defaultProps = {
  location: {},
};

export default Router;
