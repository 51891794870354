import { ROLE_SUBMITTED, PAGE_ACCESS, USER_LIST } from '../constants';

const initialState = {
  roleAdded: null,
  pageAccess: null,
  userList: null,
};

export default function UserManagmentReducer(state = initialState, action) {
  switch (action.type) {
    case ROLE_SUBMITTED:
      return {
        ...state,
        roleAdded: action.payLoad,
      };
    case PAGE_ACCESS:
      return {
        ...state,
        pageAccess: action.payLoad,
      };
    case USER_LIST:
      return {
        ...state,
        userList: action.payLoad,
      };
    default:
      return state;
  }
}
