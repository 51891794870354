import { UPDATE_PRRODUCTLIST_SUCCESS, UPDATE_CATEGORIELIST_SUCCESS, UPDATE_SUBCATEGORIELIST_SUCCESS } from '../constants';

const initialState = {
  allProductList: null,
  allCategoryList: null,
  allSubCategoryList: null,
};

export default function CBOHProductMasterReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PRRODUCTLIST_SUCCESS:
      return {
        ...state,
        allProductList: action.payLoad,
      };
    case UPDATE_CATEGORIELIST_SUCCESS:
      return {
        ...state,
        allCategoryList: action.payLoad,
      };
    case UPDATE_SUBCATEGORIELIST_SUCCESS:
      return {
        ...state,
        allSubCategoryList: action.payLoad,
      };

    default:
      return state;
  }
}
