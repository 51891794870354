import React, { Suspense } from 'react';
import { createBrowserHistory } from 'history';
import { publicRoutes } from './Route.config';
import AppRoutes from './AppRoutes';
import Loader from '../common/loader/Loader';

const FallbackComponent = () => <Loader loader="Loading..." />;
class AppRouteProvider extends React.PureComponent {
  render() {
    const history = createBrowserHistory();
    return (
      <Suspense fallback={<FallbackComponent />}>
        <AppRoutes {...{ publicRoutes, history }} />
      </Suspense>
    );
  }
}

export default AppRouteProvider;
