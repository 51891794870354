import React from 'react';
import PropTypes from 'prop-types';
import TopBarProgress from 'react-topbar-progress-indicator';
import './loader.scss';

const Loader = (props) => {
  const {
    loader, isInComponent, containerHeight, withoutSpinner, isBatchLoading,
  } = props;

  TopBarProgress.config({
    barColors: {
      0: '#c41230',
    },
    shadowBlur: 3,
    shadowColor: '#ff0000',
  });
  return (
    isBatchLoading ? (

      <div className="isBatch-Loading">
        {loader}
      </div>
    ) : (
      <div
        id={isInComponent ? 'component-loader' : 'overlay'}
        className="loading-component"
        style={{ height: containerHeight }}
        role="alert"
        data-testid="loader"
      >
        {!withoutSpinner ? <div className="spinner" /> : <TopBarProgress />}

        <h4 className="text-capitalize mt-5">{loader}</h4>
      </div>
    )

  );
};

Loader.propTypes = {
  loader: PropTypes.string,
  isInComponent: PropTypes.bool,
  withoutSpinner: PropTypes.bool,
  containerHeight: PropTypes.string,
  isBatchLoading: PropTypes.bool,
};

Loader.defaultProps = {
  loader: '',
  isInComponent: false,
  containerHeight: '',
  withoutSpinner: false,
  isBatchLoading: false,
};

export default Loader;
