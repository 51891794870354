import React from 'react';
import AggregatedSidebar from './AggregatedSidebar';
import DesktopSidebar from './DesktopSidebar';

class SidebarComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSideBar: false,
    };
  }

  handelClick = () => {
    const { isSideBar } = this.state;
    this.setState({
      isSideBar: !isSideBar,
    });
  };

  render() {
    const { isSideBar } = this.state;
    return (
      <div className={`sidebar-container ${isSideBar ? 'fullScreenSideBar' : ''}`}>
        {!isSideBar ? (
          <AggregatedSidebar {...this.props} handelClick={this.handelClick} />
        ) : (
          <>
            <DesktopSidebar {...this.props} handelClick={this.handelClick} />
          </>
        )}
      </div>
    );
  }
}
export default SidebarComponent;
